<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/gywm.png" alt="" />
      <div class="jsfnB1">关于然茜</div>
      <div class="jsfnC1">ABOUT RANCII</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">发展历程</div>
        <div class="product">THE DEVELOPMENT COURSE</div>
      </div>

      <!-- 图片 -->
      <div style="margin-top:32px">
        <img src="@/assets/fzlc.png" alt="" />
      </div>

      <!-- <div class="timerShaft">
        <el-timeline>
          <el-timeline-item
            timestamp="2023/1/1"
            placement="top"
            color="rgb(56, 182, 226)"
            type="warning "
          >
            <p>顶峰相见！</p>
          </el-timeline-item>
          <el-timeline-item
            timestamp="2018/4/12"
            placement="top"
            color="rgb(56, 182, 226)"
            type="warning "
          >
            <p>王小虎 提交于 2018/4/12 20:46</p>
          </el-timeline-item>
          <el-timeline-item
            timestamp="2018/4/3"
            placement="top"
            color="rgb(56, 182, 226)"
          >
            <p>王小虎 提交于 2018/4/3 20:46</p>
          </el-timeline-item>
          <el-timeline-item
            timestamp="2018/4/2"
            placement="top"
            color="rgb(56, 182, 226)"
          >
            <p>王小虎 提交于 2018/4/2 20:46</p>
          </el-timeline-item>
        </el-timeline>
      </div> -->

      <!-- 时间轴(不完整) -->
      <!-- <div class="bigBox">
        <div class="items">
          <template v-for="(item, index) in timeDataList">
            <div :key="index" class="itembox">
              <div class="item">
                <div class="line">
                  <div class="gardenbox">
                    <div class="garden">
                      <div class="garden2"></div>
                    </div>
                  </div>
                </div>
                <div class="valuebox">
                  <h4>{{ item.value }}</h4>
                  <div>{{ item.content }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  props: {
    timeDataList: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
            value: "2022",
            content: "2022顶峰相见！",
          },
          {
            id: 2,
            value: "2021",
            content:
              "分拆众合科技借壳上市主营业务回归IT 智慧城市、服务外包两翼齐飞",
          },
          {
            id: 3,
            value: "2020",
            content: "重组浙江海纳，将机电脱硫业务注入海纳分拆众合科技借壳上市",
          },
          {
            id: 4,
            value: "2019",
            content:
              "与微软缔结全球战略合作伙伴关系，成立合资公司上海微创；与美国道富成立合资公司网新恒天，拓展国际金融软件市场",
          },
          {
            id: 5,
            value: "2018",
            content:
              "山东然茜信息科技公司成立。同时启动与陕西彬长煤矿合作研发煤矿安全检测执法系统。",
          },
        ];
      },
    },
  },
  methods: {},
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
  letter-spacing: 1px;
}
div {
  box-sizing: border-box;
}
.bigBox {
  width: 100%;
  margin-top: 32px;
}
.items {
  display: flex;
  flex-wrap: wrap;
}
.itembox {
  width: 50%;
  border-right: 4px solid rgba(0, 61, 165, 0.22);
  position: relative;
  padding-right: 70px;
}
.items .itembox:nth-child(even) {
  border-right: none;
  padding-right: 0px;
  padding-left: 70px;
  margin-top: 100px;
  padding-bottom: 0px;
}
.items .itembox:last-child {
  padding-bottom: 0px;
}
.line {
  position: absolute;
  top: 20%;
  right: -70px;
  z-index: -2;
  width: 80px;
  border-bottom: 4px solid rgba(0, 61, 165, 0.22);
  z-index: 1;
}
.line .gardenbox {
  height: 100%;
  position: relative;
}
.line .garden {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 30px;
  height: 30px;
  background: rgba(0, 61, 165, 0.22);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(55%, 55%);
}

.line .garden2 {
  width: 16px;
  height: 16px;
  background: #004fae;
  border-radius: 50%;
}

.items .itembox:nth-child(even) .line {
  right: inherit;
  left: -70px;
}
.items .itembox:nth-child(even) .line .garden {
  right: inherit;
  left: 0px;
  transform: translate(-55%, 55%);
}
.item {
  background: #ffffff;
  box-shadow: 1px 1px 20px 0px #dae5f6;
  border-radius: 4px;
  position: relative;
}
.valuebox {
  text-align: center;
}
.timerShaft {
  margin: 32px;
  display: flex;
  justify-content: center;
}
.timerShaftCard {
  width: 700px;
}
/deep/ .el-timeline-item__tail {
  position: absolute;
  left: 6px;
  height: 100%;
  border-left: 10px solid #7d7d7d;
  top: 10px;
}
/deep/ .el-timeline-item__timestamp.is-top {
  margin-bottom: 20px;
  margin-top: 0px;
  padding-top: 32px;
}
/deep/ .el-timeline-item__wrapper {
  position: relative;
  padding-left: 36px;
  top: -27px;
}
/deep/ .el-timeline-item__node--normal {
  left: -1px;
  width: 24px;
  height: 24px;
}
</style>
